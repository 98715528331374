import { Effect } from "../../Materials/effect";

let name = 'uvAttributeDeclaration';
let shader = `#ifdef UV{X}
attribute vec2 uv{X};
#endif
`;

Effect.IncludesShadersStore[name] = shader;
/** @hidden */
export var uvAttributeDeclaration = { name, shader };

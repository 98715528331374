import { Effect } from "../../Materials/effect";

let name = 'samplerVertexDeclaration';
let shader = `#if defined(_DEFINENAME_) && _DEFINENAME_DIRECTUV == 0
varying vec2 v_VARYINGNAME_UV;
#endif
`;

Effect.IncludesShadersStore[name] = shader;
/** @hidden */
export var samplerVertexDeclaration = { name, shader };

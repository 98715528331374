import { Effect } from "../Materials/effect";
import "./ShadersInclude/clipPlaneFragmentDeclaration";
import "./ShadersInclude/clipPlaneFragment";

let name = 'linePixelShader';
let shader = `#include<clipPlaneFragmentDeclaration>
uniform vec4 color;
void main(void) {
#include<clipPlaneFragment>
gl_FragColor=color;
}`;

Effect.ShadersStore[name] = shader;
/** @hidden */
export var linePixelShader = { name, shader };

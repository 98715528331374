import { Effect } from "../../Materials/effect";

let name = 'mainUVVaryingDeclaration';
let shader = `#ifdef MAINUV{X}
varying vec2 vMainUV{X};
#endif
`;

Effect.IncludesShadersStore[name] = shader;
/** @hidden */
export var mainUVVaryingDeclaration = { name, shader };

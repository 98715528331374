import { Effect } from "../../Materials/effect";

let name = 'meshVertexDeclaration';
let shader = `uniform mat4 world;
uniform float visibility;
`;

Effect.IncludesShadersStore[name] = shader;
/** @hidden */
export var meshVertexDeclaration = { name, shader };

import { Effect } from "../../Materials/effect";
import "./sceneVertexDeclaration";
import "./meshVertexDeclaration";

let name = 'shadowMapVertexDeclaration';
let shader = `#include<sceneVertexDeclaration>
#include<meshVertexDeclaration>
`;

Effect.IncludesShadersStore[name] = shader;
/** @hidden */
export var shadowMapVertexDeclaration = { name, shader };

import { Effect } from "../Materials/effect";

let name = 'clearQuadPixelShader';
let shader = `uniform vec4 color;
void main() {
gl_FragColor=color;
}
`;

Effect.ShadersStore[name] = shader;
/** @hidden */
export var clearQuadPixelShader = { name, shader };

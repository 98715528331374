import { Effect } from "../../Materials/effect";
import "./sceneUboDeclaration";
import "./meshUboDeclaration";

let name = 'shadowMapUboDeclaration';
let shader = `layout(std140,column_major) uniform;
#include<sceneUboDeclaration>
#include<meshUboDeclaration>
`;

Effect.IncludesShadersStore[name] = shader;
/** @hidden */
export var shadowMapUboDeclaration = { name, shader };

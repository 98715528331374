import { Effect } from "../../Materials/effect";

let name = 'uvVariableDeclaration';
let shader = `#ifndef UV{X}
vec2 uv{X}=vec2(0.,0.);
#endif
#ifdef MAINUV{X}
vMainUV{X}=uv{X};
#endif
`;

Effect.IncludesShadersStore[name] = shader;
/** @hidden */
export var uvVariableDeclaration = { name, shader };

import { Effect } from "../../Materials/effect";

let name = 'sceneVertexDeclaration';
let shader = `uniform mat4 viewProjection;
#ifdef MULTIVIEW
uniform mat4 viewProjectionR;
#endif
uniform mat4 view;
uniform mat4 projection;
uniform vec4 vEyePosition;
`;

Effect.IncludesShadersStore[name] = shader;
/** @hidden */
export var sceneVertexDeclaration = { name, shader };

import { Effect } from "../../Materials/effect";

let name = 'geometryVertexDeclaration';
let shader = `
uniform mat4 viewProjection;
uniform mat4 view;`;

Effect.IncludesShadersStore[name] = shader;
/** @hidden */
export var geometryVertexDeclaration = { name, shader };
